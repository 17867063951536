import { MembershipDto } from "common";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { topicRepository } from "../repositories/topic.repository";
import { Loader } from "./loader";
import { Membership } from "./membership";

@observer
export class MyMemberships extends React.Component {

    @observable
    private myMemberships:MembershipDto[] = [];

    @observable
    private loading = true;

    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.myMemberships = await topicRepository.fetchMyMemberships();
        this.loading = false;
    }

    render() {
        return (
            <div className="card bg-secondary">
                <div className="card-header">
                    <h5>My topic memberships</h5>
                </div>
                <div className="card-body">
                    <ul className="list-group">
                        {
                            this.myMemberships
                                .map(membership => <Membership key={membership.topic.id} membership={membership} />)
                        }
                        {
                            this.loading
                            &&
                            <Loader />
                        }
                    </ul>
                </div>
            </div>
        );
    }
}