import { BoardgameSearchResultDto, BoardgameDto } from 'common';
import { Repository } from './repository';

const RESOURCE = "/api/bgg";
class BoardgameRepository extends Repository {

    async search(query:string): Promise<BoardgameSearchResultDto[]> {
        const response = await this.get(`${RESOURCE}/search/${query}`);
        return response.data as BoardgameSearchResultDto[];
    }

    async getGame(bggId: string): Promise<BoardgameDto> {
        const response = await this.get(`${RESOURCE}/game/${bggId}`);
        return response.data as BoardgameDto;
    }
}

export const boardgameRepository = new BoardgameRepository();