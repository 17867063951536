import { observer } from "mobx-react";
import React from "react";
import { topicStore } from "../stores/topic.store";

interface TopicPageDescriptorProps {
    topicId: string;
    title: string;
}

@observer
export class TopicPageDescriptor extends React.Component<TopicPageDescriptorProps> {

    private get topic() {
        return topicStore.getTopic(this.props.topicId);
    }

    render() {
        return (
            <React.Fragment>
                <div className="mb-2 border-bottom">
                    <h5>
                        <span className="text-success">{this.topic?.name}</span>
                        <span> | </span>
                        <span className="text-primary">{this.props.title}</span>
                    </h5>
                </div>
                <p className="lead mb-5">
                    { this.props.children }
                </p>
            </React.Fragment>
        );
    }
}