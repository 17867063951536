import React from "react";
import { observer } from "mobx-react";
import { computed, makeObservable } from "mobx";
import { topicStore } from "../stores/topic.store";

interface VoteResultsProps {
    topicId: string;
}

@observer
export class VoteResults extends React.Component<VoteResultsProps> {

    constructor(props:VoteResultsProps) {
        super(props);
        makeObservable(this);
    }

    private get topic() {
        return topicStore.getTopic(this.props.topicId)!;
    }

    @computed
    private get winner() {
        return this.getPlacedNomination(1)?.name;
    }
    
    @computed
    private get place2() {
        return this.getPlacedNomination(2)?.name;
    }

    @computed
    private get place3() {
        return this.getPlacedNomination(3)?.name;
    }

    private getPlacedNomination(place:number) {
        if (!this.topic || this.topic.resultRanking.length < place) {
            return null;
        }
        return this.topic.resultRanking[place - 1];
    }

    render() {
        if (!this.topic?.isClosed) {
            return null;
        }

        if (!this.winner) {
            return (
                <div className="card text-white bg-danger my-1">
                    <div className="card-body">
                        Oh no! Something went wrong tallying the results, and no winner was determined.
                    </div>
                </div>
            )
        }

        return (
            <div className="card text-white bg-success my-1">
                <div className="card-header">The votes are in. The winner is...</div>
                <div className="card-body">
                    <h1>1. {this.winner}</h1>
                    {this.place2 && <h4>2. {this.place2}</h4>}
                    {this.place3 && <h4>3. {this.place3}</h4>}
                </div>
            </div>
        );
    }
}