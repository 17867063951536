import React from "react";
import { GoogleButton } from "../components/google-button";
import { Landing } from "../components/landing";

export class Login extends React.Component {
    render() {
        return (
            <div className="container mt-5 mx-auto">
                <Landing />
                <div className="card bg-light text-dark">
                    <div className="card-body text-center mx-auto">
                        <p className="lead">Please sign in to get started</p>
                        <div className="mb-2" />
                        <GoogleButton />
                        <div className="alert alert-info mt-3">
                            <b>Why do I need a Google sign in?</b>
                            <p>
                                Settler requires users to log in so that votes can be tied to a user.
                                This allowes users to get back to their votes later if they want to make
                                a change before the vote closes. Currently, only Google sign in is supported,
                                but more options will be available in the future.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}