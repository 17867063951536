import React from "react";
import { Link } from "react-router-dom";

export class About extends React.Component {
    render() {
        return (
            <div>
                <h1>About Settler</h1>
                <p>
                    During the Coronavirus pandemic, my friends and I started playing a lot of online board games. 
                    We love board games, but quickly discovered that we are terrible at choosing them. I wrote Settler
                    to help with that. Even though I made it with board gaming in mind, it can be used for other tough group
                    decisions, like deciding where to eat. It uses a voting algorithm that aims to find 
                    a consensus for the whole group, rather than appeasing the majority. For example, even if the majority
                    ranks a game highly, if the minority hates the game, it should not be a strong contender. You can read more
                    about the voting algorithm <Link to="/about/calculation">here</Link>.
                </p>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>Want to support Settler?</h3>
                            </div>
                            <div className="card-body">
                                Settler isn't monetized, so if you want to make a financial contribution to the development, or chip in to help pay for hosting costs, I have just the button for you :)
                                <div className="mt-3">
                                    <a className="btn btn-success" href="https://www.paypal.com/donate?business=DGFD2Z3Y97PLS&currency_code=USD">Donate with Paypal</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 col-sm-12 mt-md-0 mt-sm-3">
                        <div className="card">
                            <div className="card-header">
                                <h3>Want to get in touch?</h3>
                            </div>
                            <div className="card-body">
                                Have a bug report or feature request? I can't promise that I'll get to it, but I'd love to hear your ideas about how to make Settler better.
                                <div className="mt-3">
                                    <a className="btn btn-primary" href="https://docs.google.com/forms/d/e/1FAIpQLSd32rMEqbB14-4gfMMTdhCb6-8D-zGRg8o8g8br0oQmf8IDmA/viewform?usp=sf_link">Contact Form</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}