import { MembershipDto } from "common";
import React from "react";
import { Link } from "react-router-dom";

interface MembershipProps {
    membership: MembershipDto;
}

export class Membership extends React.Component<MembershipProps> {

    private get topic() {
        return this.props.membership.topic;
    }

    private get createdDate() {
        if (this.topic.createdDate) {
            return new Date(this.topic.createdDate);
        }
        return null;
    }

    render() {
        return (
            <li className="list-group-item d-flex justify-content-between align-items-center">
                <div className="col">
                    <b>{this.topic.name}</b>
                    {
                        this.createdDate
                        &&
                        <div>
                            <i>Created {this.createdDate.toLocaleDateString()}</i>
                        </div>
                    }
                </div>
                <div className="ms-auto me-3">
                    {
                        this.renderRole()
                    }
                </div>
                <div className="ms-auto">
                    <Link to={`/vote/${this.topic.id}`} className="btn btn-secondary"><i className="fa fa-arrow-right" /></Link>
                </div>
            </li>
        );
    }

    private renderRole() {
        if (this.props.membership.member.isManager) {
            return <span className="badge badge-warning">Manager</span>
        }
        else if (this.props.membership.member.isVotingMember) {
            return <span className="badge badge-primary">Voter</span>
        }
        return <span className="badge badge-info">Observer</span>
    }
}