import React from "react";
import { Link } from "react-router-dom";

export class Landing extends React.Component {
    render() {
        return (
            <div className="jumbotron">
                <h1 className="display-3"><span className="text-success" style={{fontWeight: "bolder"}}><i className="fas fa-poll"></i> Settler</span></h1>
                <p className="lead">Helping you and your friends pick a board game (or something else) with <Link to="/about/calculation">ranked choice voting</Link></p>
            </div>
        );
    }
}