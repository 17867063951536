import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface LoaderButtonProps extends ButtonProps {
    onClick():Promise<any>;
}

@observer
export class LoaderButton extends React.Component<LoaderButtonProps> {

    @observable
    private loading = false;
    
    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    private async handleClick() {
        this.loading = true;
        await this.props.onClick();
        this.loading = false;
    }

    @computed
    private get wrappedDisabled() {
        return this.loading || this.props.disabled;
    }

    render() {
        const {onClick, disabled, ...otherProps} = {...this.props};

        return (
            <Button onClick={this.handleClick} disabled={this.wrappedDisabled} {...otherProps}>
                {this.props.children}
                {
                    this.loading 
                    && 
                    <Spinner className="ms-2" as="span" size="sm" animation="border" />
                }
            </Button>
        );
    }
}