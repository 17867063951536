import React from "react";
import { observer } from "mobx-react";
import { GoogleLogout, GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { authStore } from "../stores/auth.store";
import { boundMethod } from "autobind-decorator";

const CLIENT_ID = "1035822304766-ld0jndg5qit7g1lrtjbrvbudnudpded0.apps.googleusercontent.com";

@observer
export class GoogleButton extends React.Component {

    @boundMethod
    private handleLogin(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
        console.info("Log in");
        const loginResponse = response as GoogleLoginResponse;
        authStore.login(loginResponse.tokenId, loginResponse?.getBasicProfile()?.getGivenName());
    }

    @boundMethod
    private handleLoginFailure() {
        console.warn("Login failure");
    }

    @boundMethod
    private handleAutoLoadFinished(loggingIn:boolean) {
        console.info(`Auto load finished. Logging in = ${loggingIn}`);
        if (!loggingIn) {
            authStore.logout();
        }
    }

    @boundMethod
    private handleLogout() {
        console.info("Log out");
        authStore.logout();
    }

    render() {
        if (authStore.isLoggedIn) {
            return (
                <span>
                    <span className="me-4">
                        Hi, {authStore.givenName}!
                    </span>
                    <GoogleLogout
                        clientId={CLIENT_ID}
                        buttonText="Sign out"
                        onLogoutSuccess={this.handleLogout} />
                </span>
            )
        }
        else {
            return (
                <div style={authStore.pending ? {display: "none"} : {}}>
                    <GoogleLogin
                        clientId={CLIENT_ID}
                        buttonText="Sign in"
                        onSuccess={this.handleLogin}
                        onAutoLoadFinished={this.handleAutoLoadFinished }
                        onFailure={this.handleLoginFailure}
                        cookiePolicy="single_host_origin"
                        isSignedIn={authStore.pending}
                        responseType="token" />
                </div>
            )
        } 

    }
}