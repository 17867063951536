import { MemberDto, newNominationDto, NominationDto, TopicMode } from "common";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";
import { Loader } from "./loader";
import { LoaderButton } from "./loader-button";
import { BoardgameNominator } from "./nominators/boardgame-nominator";
import { GeneralNominator } from "./nominators/general-nominator";
import { NominatorProps } from "./nominators/nominator-props";

interface NominatorContainerProps {
    topicId: string;
    member: MemberDto;
}

@observer
export class NominatorContainer extends React.Component<NominatorContainerProps> {
    @observable
    private newNomination: NominationDto = newNominationDto();

    @observable
    private show = false;

    constructor(props: any) {
        super(props);
        makeObservable(this);
    }

    private get topic() {
        return topicStore.getTopic(this.props.topicId)!;
    }

    private get topicId() {
        return this.props.topicId;
    }

    @action.bound
    private async handleClickAddNomination() {
        await this.addNomination()
    }

    @action.bound
    private handleClickShow() {
        this.show = true;
    }

    @action.bound
    private handleClickHide() {
        this.show = false;
    }

    private async addNomination() {
        if (!this.newNomination.name) {
            return;
        }

        const topic = await topicRepository.addNomination(this.topicId, this.newNomination);
        topicStore.storeTopic(topic);
        this.newNomination = newNominationDto();
    }

    @computed
    private get nominatorClass(): React.ComponentType<NominatorProps> {
        switch (this.topic?.mode) {
            case TopicMode.BoardGame:
                return BoardgameNominator;
            default:
                return GeneralNominator;
        }
    }

    @computed
    private get canNominate() {
        if (!this.topic) {
            return false;
        }
        if (this.topic.isClosed) {
            return false;
        }

        if (this.props.member.isManager) {
            return true;
        }

        if (!this.topic.allowNominate) {
            return false;
        }
        return this.props.member.isVotingMember;
    }

    render() {

        if (!this.topic) {
            return <Loader />;
        }

        if (!this.canNominate) {
            return null;
        }

        const Nominator = this.nominatorClass;

        return (
            <React.Fragment>
                <div className="card mb-5">
                    <div className="card-header">
                        <Row>
                            <Col>
                                <h5>Want to nominate something?</h5>
                            </Col>
                            <Col xs="auto">
                                {
                                    this.show
                                        ? <button className="btn btn-primary" onClick={this.handleClickHide}>Hide</button>
                                        : <button className="btn btn-primary" onClick={this.handleClickShow}>Show</button>
                                }
                            </Col>
                            
                        </Row>
                    </div>
                    {
                        this.show
                        &&
                        <React.Fragment>
                            <div className="card-body">
                                <Nominator newNomination={this.newNomination} />
                            </div>
                            <div className="card-footer text-end">
                                <LoaderButton
                                    variant="success"
                                    disabled={!this.newNomination.name}
                                    onClick={this.handleClickAddNomination}>
                                    Nominate
                                </LoaderButton>
                            </div>
                        </React.Fragment>
                    }

                </div>
            </React.Fragment>
        );
    }
}