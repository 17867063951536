import { modeDisplay, TopicMode } from "common";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent, KeyboardEvent } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";
import { LoaderButton } from "./loader-button";

const ENTER_KEY = "Enter";

@observer
export class CreateTopic extends React.Component<RouteComponentProps> {

    @observable
    private topicName = "";

    @observable
    private mode = TopicMode.BoardGame;

    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    @computed
    private get displayMode():string {
        return modeDisplay(this.mode);
    }

    @action.bound
    private async handleClickCreateTopic() {
        await this.createTopic();
    }

    @action.bound
    private handleChangeTopicName(event: ChangeEvent<HTMLInputElement>) {
        this.topicName = event.target.value;
    }

    @action.bound
    private handleChangeTopicMode(eventKey: string|null) {
        this.mode = eventKey as unknown as TopicMode;
    }

    @action.bound
    private async handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === ENTER_KEY) {
            await this.createTopic();
        }
    }

    private async createTopic() {
        const topic = await topicRepository.createTopic(this.topicName, this.mode);
        const topicId = topic.id;
        topicStore.storeTopic(topic);
        this.props.history.push(`manage/${topicId}`)
    }

    render() {
        return (
            <div className="card text-white bg-primary">
                <div className="card-header">
                    <h5>Create a new topic</h5>
                </div>
                <div className="card-body">
                    <div className="input-group">
                        <input 
                            onChange={this.handleChangeTopicName}
                            type="text" 
                            onKeyUp={this.handleKeyUp}
                            placeholder="Topic name" 
                            className="form-control" />
                        <DropdownButton
                            variant="secondary"
                            onSelect={this.handleChangeTopicMode}
                            title={this.displayMode}>
                                <Dropdown.Item eventKey={TopicMode.BoardGame}>{modeDisplay(TopicMode.BoardGame)}</Dropdown.Item>
                                <Dropdown.Item eventKey={TopicMode.General}>{modeDisplay(TopicMode.General)}</Dropdown.Item>
                        </DropdownButton>
                        <div className="input-group-append">
                            <LoaderButton 
                                variant="success"
                                onClick={this.handleClickCreateTopic}>
                                Create
                            </LoaderButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}