import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { boundMethod } from "autobind-decorator";

const copy = require("clipboard-copy");

interface CopyableLinkProps {
    relativeLink: string
};

@observer
export class CopyableLink extends React.Component<CopyableLinkProps> {

    private get absoluteLink() {
        return `${window.location.origin}/${this.props.relativeLink}`;
    }

    @boundMethod
    private handleClickCopy() {
        copy(this.absoluteLink);
    }
    
    render() {
        return (
            <div className="input-group mt-5 mb-5">
                <input className="form-control" type="text" readOnly={true} value={this.absoluteLink}/>
                <div className="input-group-append">
                    <button className="btn btn-warning" onClick={this.handleClickCopy}>Copy</button>
                    <Link className="btn btn-success" to={`/${this.props.relativeLink}`}>Go</Link>
                </div>
            </div>
        );
    }
}