import {  makeAutoObservable } from "mobx";

class AuthStore {

    constructor() {
        makeAutoObservable(this);
    }
    
    public pending = true;
    private googleIdToken:string|undefined;
    public givenName:string|undefined;

    get isLoggedIn() {
        return Boolean(this.givenName);
    }

    get bearerToken() {
        return this.googleIdToken;
    }

    login(googleIdToken:string, givenName:string) {
        this.googleIdToken = googleIdToken;
        this.givenName = givenName;
        this.pending = false;
    }

    logout() {
        this.googleIdToken = undefined;
        this.givenName = undefined;
        this.pending = false;
    }
}

export const authStore = new AuthStore();