import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ErrorInfo } from "react";

@observer
export class ErrorBoundary extends React.Component {

    @observable
    private hasError = false;

    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.hasError = true;
        console.error(error);
        console.error(errorInfo);
    }

    render() {
        if (this.hasError) {
            return (
                <div className="alert alert-danger">
                    <h3>Whoops :(</h3>
                    <p>
                        Something went wrong. Sorry for the inconvenience!
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}