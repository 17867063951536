import { authStore } from "../stores/auth.store";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import axios from "axios";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class Repository {
    
    get bearerHeader() {
        if (!authStore.isLoggedIn) {
            return {};
        }
        return {
            Authorization: `Bearer ${authStore.bearerToken}`
        };
    }

    async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        try {
            return await axios.get(url, this.withBearerToken(config));
        }
        catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    async post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        try {
            return await axios.post(url, data, this.withBearerToken(config));
        }
        catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    async put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        try {
            return await axios.put(url, data, this.withBearerToken(config));
        }
        catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    async delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        try {
            return await axios.delete(url, this.withBearerToken(config));
        }
        catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    private handleError(e:any) {
        if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
        }
        else {
            toast.error("Hmm, something went wrong. Sorry about that");
            console.error(JSON.stringify(e));
        }
    }

    private withBearerToken(config?: AxiosRequestConfig):AxiosRequestConfig {
        return {...config, headers: {...config?.headers, ...this.bearerHeader}};
    }
}