import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { match } from "react-router";
import { Loader } from "../components/loader";
import { VoteResults } from "../components/vote-results";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";
import { VoteOrder } from "../components/vote-order";
import { Link } from "react-router-dom";
import { MemberDto, populateVote } from "common";
import { TopicPageDescriptor } from "../components/topic-page-descriptor";
import { NominatorContainer } from "../components/nominator-container";
import { LoaderButton } from "../components/loader-button";

interface Params {
    topicId: string;
}

export interface VoteProps {
    match: match<Params>;
}

@observer
export class Vote extends React.Component<VoteProps> {


    constructor(props:VoteProps) {
        super(props);
        makeObservable(this);
    }

    @observable
    private member: MemberDto | undefined;

    private get canManage() {
        return this.member?.isManager;
    }

    private get topic() {
        return topicStore.getTopic(this.topicId);
    }

    async componentDidMount() {
        this.member = await topicRepository.join(this.topicId);
    }

    private get topicId() {
        return this.props.match.params.topicId;
    }

    @action.bound
    private async handleClickWithdraw() {
        this.member = await topicRepository.withdraw(this.topicId);
        populateVote(this.member, this.topic?.nominations?.map(n => n.id) || []);
    }

    @action.bound
    private async handleClickJoinVote() {
        this.member = await topicRepository.participate(this.topicId);
        populateVote(this.member, this.topic?.nominations?.map(n => n.id) || []);
    }

    @action.bound
    private async handleClickReady() {
        this.member = await topicRepository.ready(this.topicId);
    }

    @action.bound
    private async handleClickNotReady() {
        this.member = await topicRepository.notReady(this.topicId);
    }

    render() {
        if (!this.member || !this.topic) {
            return <Loader />;
        }

        return (
            <div>
                <TopicPageDescriptor topicId={this.topicId} title="Vote">
                    You can add nominations and drag and drop to rearrange nominations to match your preference. When everyone has had enough
                    time, the topic manager will close the vote, and the winner will be <Link to="/about/calculation">calculated</Link>
                </TopicPageDescriptor>
                <VoteResults topicId={this.topicId} />
                {this.renderInfoList()}
                <hr/>
                <NominatorContainer topicId={this.topicId} member={this.member} />           
                <VoteOrder topicId={this.topicId} member={this.member} />
            </div>
        );
    }

    private renderInfoList() {
        return (
            <ul className="list-group">
                {this.renderParticipationListItem()}
            </ul>
        );
    }

    private renderParticipationListItem() {
        let participationDisplay = "an observer";
        let listClassName = "list-group-item-info";
        if (this.member?.isVotingMember) {
            participationDisplay = "a voter";
            listClassName = "list-group-item-success";
        }

        return (
            <li className={`list-group-item ${listClassName}`}>
                <span className="me-2">
                    <b>{this.topic?.totalVoters}</b> voters have joined.
                    You are <b>{participationDisplay}</b>
                </span>
                {
                    this.member?.isVotingMember
                    ? <LoaderButton disabled={this.topic?.isClosed} variant="danger" onClick={this.handleClickWithdraw}>Withdraw vote</LoaderButton>
                    : <LoaderButton disabled={this.topic?.isClosed} variant="success" onClick={this.handleClickJoinVote}>Join as Voter</LoaderButton>
                }
                {
                    this.canManage && <Link className="ms-2 btn btn-warning" to={`/manage/${this.topicId}`}>Manage Topic</Link>
                }
                {
                    this.member?.isVotingMember
                    &&
                    (
                        this.member?.isReady
                        ? <LoaderButton disabled={this.topic?.isClosed} className="ms-2" variant="warning" onClick={this.handleClickNotReady}>Mark as not ready</LoaderButton>
                        : <LoaderButton disabled={this.topic?.isClosed} className="ms-2" variant="success" onClick={this.handleClickReady}>Mark as ready</LoaderButton>
                    )
                }
            </li>
        );
    }
}