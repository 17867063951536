import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { action, computed, makeObservable, observable } from "mobx";
import { NominatorProps } from "./nominator-props";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { boardgameRepository } from "../../repositories/boardgame.repository";
import { Col, Form, Row } from "react-bootstrap";

interface BoardGameSearchOption {
    id: string;
    label: string;
}

@observer
export class BoardgameNominator extends React.Component<NominatorProps> {

    @observable
    private isBoardgameLoading = false;

    @observable
    private selectedBoardGameOption: BoardGameSearchOption | undefined;

    @observable
    private boardGameSearchOptions: BoardGameSearchOption[] = [];

    constructor(props: NominatorProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    private async handleBoardgameSearch(query: string) {
        this.isBoardgameLoading = true;
        const searchResults = await boardgameRepository.search(query);
        this.boardGameSearchOptions = searchResults
            .map(searchResult => ({
                label: `${searchResult.name} (${searchResult.year})`,
                id: searchResult.id
            }));
        this.isBoardgameLoading = false;
    }

    @action.bound
    private async handleBoardgameChange(selected: BoardGameSearchOption[]) {
        this.selectedBoardGameOption = selected[0];
        if (!this.selectedBoardGameOption) {
            this.props.newNomination.name = "";
            this.props.newNomination.description = "";
            this.props.newNomination.link = "";
            this.props.newNomination.thumbnailUrl = "";
            return;
        }
        const boardgame = await boardgameRepository.getGame(this.selectedBoardGameOption.id);
        this.props.newNomination.name = this.selectedBoardGameOption.label;

        const roundedWeight = Number(boardgame.averageWeight).toFixed(1);
        const roundedRating = Number(boardgame.averageRating).toFixed(1);

        const descriptionHeader = `<b>${boardgame.minPlayers}-${boardgame.maxPlayers}p  |  ${boardgame.playingTime} minutes  |  ${roundedWeight} weight  |  ${roundedRating} rating (${boardgame.usersRated} users)</b>`;
        this.props.newNomination.description = `${descriptionHeader}\n\n${boardgame.description}`;

        this.props.newNomination.thumbnailUrl = boardgame.thumbnailUrl;
        this.props.newNomination.link = `https://boardgamegeek.com/boardgame/${boardgame.id}`;
    }

    @action.bound
    private handleBoardgameSearchChange(text:string) {
        this.props.newNomination.name = text;
        this.selectedBoardGameOption = undefined;
    }

    @action.bound
    private handleChangeNewNominationDescription(event: ChangeEvent<HTMLTextAreaElement>) {
        this.props.newNomination.description = event.currentTarget.value;
    }

    @action.bound
    private handleChangeNewNominationLink(event: ChangeEvent<HTMLInputElement>) {
        this.props.newNomination.link = event.currentTarget.value;
    }

    @action.bound
    private typeaheadFilter() {
        // Filtering is done on the server
        return true;
    }

    @computed
    private get selectedOptions() {
        // The name might have been cleared by a parent. We don't want to treat this
        // item as selected anymore if that's the case.
        if (this.props.newNomination.name && this.selectedBoardGameOption) {
            return [this.selectedBoardGameOption];
        }
        return [];
    }

    render() {
        return (
            <Row>
                <div style={{ width: "100px", height: "100px" }}>
                    {
                        this.props.newNomination.thumbnailUrl
                        && 
                        <img className="img-thumbnail" alt="Thumbnail" src={this.props.newNomination.thumbnailUrl} />
                    }
                </div>
                <Col>
                    <Row>
                        <Form.Group as={Col}>
                            <AsyncTypeahead
                                id="bgg-typeahead"
                                placeholder="Game name"
                                isLoading={this.isBoardgameLoading}
                                filterBy={this.typeaheadFilter}
                                onSearch={this.handleBoardgameSearch}
                                onChange={this.handleBoardgameChange}
                                onInputChange={this.handleBoardgameSearchChange}
                                selected={this.selectedOptions}
                                options={this.boardGameSearchOptions} />
                        </Form.Group>
                    </Row>
                    <Row className="mt-1">
                        <Form.Group as={Col}>
                            <textarea
                                className="form-control"
                                placeholder="Description (Optional)"
                                rows={4}
                                value={this.props.newNomination.description}
                                onChange={this.handleChangeNewNominationDescription} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <input
                                className="form-control"
                                type="url"
                                placeholder="Link (Optional)"
                                value={this.props.newNomination.link}
                                onChange={this.handleChangeNewNominationLink} />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
        );
    }
}