import React from 'react';
import './app.css';
import { observer } from "mobx-react";
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Home } from './pages/home';
import { Vote } from './pages/vote';
import { ManageTopic } from './pages/manage-topic';
import { SettlerNav } from './components/settler-nav';
import { authStore } from './stores/auth.store';
import { ToastContainer } from 'react-toastify';
import { Loader } from './components/loader';
import { AboutCalculation } from './pages/about.calculation';
import LogRocket from "logrocket";
import { ErrorBoundary } from './components/error-boundary';
import { Login } from './pages/login';
import { About } from './pages/about';

@observer
export class App extends React.Component {

    constructor(props: any) {
        LogRocket.init('ito3ce/settler');
        super(props);
    }

    render() {
        return (

            <ErrorBoundary>
                <div>

                    <BrowserRouter>
                        <SettlerNav />
                        <ToastContainer />

                        <div className="container mt-5">
                            <ErrorBoundary>
                                {
                                    authStore.pending
                                        ? <Loader />
                                        : this.renderRoutes()
                                }
                            </ErrorBoundary>
                        </div>

                        <div className="footer">
                            <div className="container text-center mx-auto copyright">
                                Copyright {"\u00a9"} {new Date().getFullYear()} by Eric Pernice. All Rights Reserved.
                        </div>
                        </div>

                    </BrowserRouter>
                </div>

            </ErrorBoundary>
        );
    }

    renderRoutes() {
        if (authStore.isLoggedIn) {
            return this.renderLoggedInRoutes();
        }
        return this.renderLoggedOutRoutes();
    }

    renderLoggedInRoutes() {
        return (
            <Switch>
                <Route path="/about" exact component={About} />
                <Route path="/vote/:topicId" component={Vote} />
                <Route path="/manage/:topicId" component={ManageTopic} />
                <Route path="/about/calculation" component={AboutCalculation} />
                <Route path="/" exact component={Home} />
            </Switch>
        )
    }

    renderLoggedOutRoutes() {
        return (
            <Switch>
                <Route path="/about" exact component={About} />
                <Route path="/about/calculation" component={AboutCalculation} />
                <Route path="/" component={Login} />
            </Switch>
        );
    }
}
