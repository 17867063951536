import { action, makeObservable, observable, when } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { match } from "react-router";
import { toast } from "react-toastify";
import { CopyableLink } from "../components/copyable-link";
import { Loader } from "../components/loader";
import { LoaderButton } from "../components/loader-button";
import { TopicPageDescriptor } from "../components/topic-page-descriptor";
import { VoteResults } from "../components/vote-results";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";

interface Params {
    topicId: string;
}

export interface ManageTopicProps {
    match: match<Params>;
}

@observer
export class ManageTopic extends React.Component<ManageTopicProps> {

    @observable
    private autoClosePlayerCount:string = "";

    async componentDidMount() {
        await when(() => Boolean(this.topic));
        if (this.topic!.autoClosePlayerCount) {
            this.autoClosePlayerCount = String(this.topic!.autoClosePlayerCount);
        }
    }

    private get topic() {
        return topicStore.getTopic(this.topicId);
    }

    private get topicId() {
        return this.props.match.params.topicId;
    }

    private get link() {
        return `vote/${this.topicId}`;
    }

    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    private async handleClickCloseTopic() {
        const topic = await topicRepository.closeTopic(this.topicId);
        topicStore.storeTopic(topic);
    }

    @action.bound
    private async handleClickReopenTopic() {
        const topic = await topicRepository.reopenTopic(this.topicId);
        topicStore.storeTopic(topic);
    }

    @action.bound
    private async handleClickToggleCanVeto() {
        const topic = await topicRepository.updateAllowVetoes(this.topicId, !this.topic?.allowVeto);
        topicStore.storeTopic(topic);
        toast.success("Saved");
    }

    @action.bound
    private async handleClickToggleCanNominate() {
        const topic = await topicRepository.updateAllowNominate(this.topicId, !this.topic?.allowNominate);
        topicStore.storeTopic(topic);
        toast.success("Saved");
    }
    
    @action.bound
    private async handleClickSaveAutoClose() {
        if (this.autoClosePlayerCount === "") {
            toast.error("Please specify a minimum voter count to enable auto close");
            return;
        }
        const topic = await topicRepository.updateAutoClosePlayerCount(this.topicId, Number(this.autoClosePlayerCount));
        topicStore.storeTopic(topic);
        toast.success("Saved");
    }

    @action.bound
    private async handleClickDisableAutoClose() {
        const topic = await topicRepository.updateAutoClosePlayerCount(this.topicId, undefined);
        topicStore.storeTopic(topic);
        this.autoClosePlayerCount = "";
        toast.success("Saved");
    }

    @action.bound
    private handleChangeAutoClosePlayerCount(event: ChangeEvent<HTMLInputElement>) {
        if (isNaN(Number(event.currentTarget.value))) {
            this.autoClosePlayerCount = "";
            return;
        }
        this.autoClosePlayerCount = event.currentTarget.value;
    }


    render() {
        if (!this.topic) {
            return <Loader />;
        }

        return (
            <div>
                <TopicPageDescriptor topicId={this.topicId} title="Manage Topic">
                    Copy the link below, and send it out to everyone you want to invite to participate in the vote
                </TopicPageDescriptor>
                <CopyableLink relativeLink={this.link} />
                {
                    !this.topic.isClosed
                    &&
                    <div className="mx-auto text-center">
                        <p className="lead mb-3">
                            Ready to close the topic?
                        </p>
                        <LoaderButton variant="danger" onClick={this.handleClickCloseTopic}>Close Topic</LoaderButton>
                    </div>
                }
                {
                    this.topic.isClosed
                    &&
                    <div className="mx-auto text-center">
                        <p className="lead mb-3">
                            Want to re-open this topic?
                        </p>
                        <LoaderButton variant="success" onClick={this.handleClickReopenTopic}>Reopen Topic</LoaderButton>
                    </div>
                }
                <div className="card mt-3">
                    <div className="card-header">
                        <h5>Topic Settings</h5>
                    </div>
                    <div className="card-body">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <span className="me-2">Vetoes are {this.topic.allowVeto ? this.renderEnabled() : this.renderDisabled()}</span>
                                {
                                    this.topic.allowVeto
                                        ? <LoaderButton variant="secondary" onClick={this.handleClickToggleCanVeto}>Disable</LoaderButton>
                                        : <LoaderButton variant="success" onClick={this.handleClickToggleCanVeto}>Enable</LoaderButton>
                                }
                            </li>
                            <li className="list-group-item">
                                <span className="me-2">Nominations are {this.topic.allowNominate ? this.renderEnabled() : this.renderDisabled()}</span>
                                {
                                    this.topic.allowNominate
                                        ? <LoaderButton variant="secondary" onClick={this.handleClickToggleCanNominate}>Disable</LoaderButton>
                                        : <LoaderButton variant="success" onClick={this.handleClickToggleCanNominate}>Enable</LoaderButton>
                                }
                            </li>
                            <li className="list-group-item">
                                <span className="me-2">Automatically close topic when all voters are ready is {this.topic.autoClosePlayerCount ? this.renderEnabled() : this.renderDisabled()}</span>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>Voters</InputGroup.Text>
                                            <Form.Control 
                                                type="text" 
                                                onChange={this.handleChangeAutoClosePlayerCount} 
                                                value={this.autoClosePlayerCount}
                                                placeholder="Minimum voter count"/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                    {
                                        this.topic.autoClosePlayerCount
                                            ? (
                                                <>
                                                    <LoaderButton variant="primary" onClick={this.handleClickSaveAutoClose}>Save</LoaderButton>
                                                    <LoaderButton variant="secondary" onClick={this.handleClickDisableAutoClose}>Disable</LoaderButton>
                                                </>
                                            )
                                            : <LoaderButton variant="success" onClick={this.handleClickSaveAutoClose}>Enable</LoaderButton>
                                    }
                                    </Col>
                                </Row>
                            </li>
                        </ul>
                    </div>
                </div>


                <hr />
                <VoteResults topicId={this.topicId} />
            </div>
        );
    }

    private renderEnabled() {
        return this.renderStatusPhrase("enabled", "text-success");
    }

    private renderDisabled() {
        return this.renderStatusPhrase("disabled", "text-secondary");
    }

    private renderStatusPhrase(phrase:string, textClass:string) {
        return <span className={textClass}><b>{phrase}</b></span>;
    }
}