import React from "react";
import { observer } from "mobx-react";
import { GoogleButton } from "./google-button";
import { ErrorBoundary } from "./error-boundary";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavbarBrand, Container } from "react-bootstrap";

@observer
export class SettlerNav extends React.Component {

    render() {
        return (

            <Navbar bg="dark" variant="dark" className="text-white">
                <Container>
                    <NavbarBrand href="/"><i className="fas fa-poll"></i> Settler</NavbarBrand>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <Nav.Link as={Link} className="text-white" to="/">Home</Nav.Link>
                            <Nav.Link as={Link} className="text-white" to="/about">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <ErrorBoundary>
                        <form className="form-inline" style={{textAlign: "right"}}>
                            <GoogleButton />
                        </form>
                    </ErrorBoundary>
                </Container>
            </Navbar>
        );
    }
}