import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { action, makeObservable } from "mobx";
import { NominatorProps } from "./nominator-props";
import { Col, Form, Row } from "react-bootstrap";

@observer
export class GeneralNominator extends React.Component<NominatorProps> {

    constructor(props: NominatorProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    private handleChangeNewNominationTitle(event: ChangeEvent<HTMLInputElement>) {
        this.props.newNomination.name = event.currentTarget.value;
    }

    @action.bound
    private handleChangeNewNominationDescription(event: ChangeEvent<HTMLTextAreaElement>) {
        this.props.newNomination.description = event.currentTarget.value;
    }

    @action.bound
    private handleChangeNewNominationLink(event: ChangeEvent<HTMLInputElement>) {
        this.props.newNomination.link = event.currentTarget.value;
    }

    render() {
        return (
            <Row>
                <Col>
                    <Row>
                        <Form.Group as={Col}>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Give your nomination a title"
                                value={this.props.newNomination.name}
                                onChange={this.handleChangeNewNominationTitle} />
                        </Form.Group>
                    </Row>
                    <Row className="mt-1">
                        <Form.Group as={Col}>
                            <textarea
                                className="form-control"
                                placeholder="Description (Optional)"
                                value={this.props.newNomination.description}
                                onChange={this.handleChangeNewNominationDescription} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <input
                                className="form-control"
                                type="url"
                                placeholder="Link (Optional)"
                                value={this.props.newNomination.link}
                                onChange={this.handleChangeNewNominationLink} />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
        );
    }
}