import { topicRepository } from '../repositories/topic.repository';
import { TopicDto } from 'common';
import { makeAutoObservable } from "mobx";
import io from "socket.io-client";

export class TopicStore {
    
    private topics: {[id:string]:TopicDto} = {};
    private socket = io();

    constructor() {
        makeAutoObservable(this);
        this.socket.on('notifyTopic', (topic:TopicDto) => {
            this.storeTopic(topic);
        });
        this.socket.io.on('reconnect', () => {
            console.log("Reconnected");
            for (let topicId of Object.keys(this.topics)) {
                console.log("Rejoining topic: " + topicId);
                this.subscribeToTopicChanges(topicId);
            }
        });
    }

    public getTopic(topicId:string):TopicDto|null {
        if (this.topics[topicId]) {
            return this.topics[topicId];
        }

        this.fetchTopic(topicId);
        return null;
    }

    public async fetchTopic(topicId: string) {
        const topic = await topicRepository.fetchTopic(topicId);
        this.storeTopic(topic);
        this.subscribeToTopicChanges(topicId);
    }

    private subscribeToTopicChanges(topicId:string) {
        this.socket.emit('joinTopic', topicId);
    }

    public storeTopic(topic:TopicDto) {
        console.debug(`Storing change to topic ${topic.id}`);

        this.topics[topic.id] = this.topics[topic.id] || {};
        const existingModifiedDate = asDateOrNull(this.topics[topic.id]?.modifiedDate);
        const newModifiedDate = asDateOrNull(topic?.modifiedDate);

        if (existingModifiedDate && newModifiedDate && existingModifiedDate > newModifiedDate) {
            console.log(`Refusing to store ${topic.id}, which is out of date. Existing modified date is ${existingModifiedDate} and new modified date is ${newModifiedDate}`);
            return;
        }

        this.topics[topic.id] = topic;
        this.subscribeToTopicChanges(topic.id);
    }
}

function asDateOrNull(dateStr?:string):Date|null {
    if (!dateStr) {
        return null;
    }
    return new Date(dateStr);
}

export const topicStore = new TopicStore();