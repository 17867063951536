import { MemberDto, populateVote } from "common";
import { action, computed, IReactionDisposer, makeObservable, reaction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";
import { Loader } from "./loader";
import { VoteItem } from "./vote-item";

export interface VoteOrderProps {
    member: MemberDto;
    topicId:string;
}

@observer
export class VoteOrder extends React.Component<VoteOrderProps> {
    
    private nominationChangeAutoRunDisposer:IReactionDisposer|undefined;

    constructor(props:VoteOrderProps) {
        super(props);
        makeObservable(this);
    }

    private get member() {
        return this.props.member;
    }

    private get topic() {
        return topicStore.getTopic(this.props.topicId)!;
    }

    componentDidMount() {
        this.nominationChangeAutoRunDisposer = reaction(
            () => this.topic.nominations,
            () => {
                populateVote(this.member, this.topic.nominations.map(nomination => nomination.id));
            },
            {
                fireImmediately: true
            }
        );
    }

    componentWillUnmount() {
        if (this.nominationChangeAutoRunDisposer) {
            this.nominationChangeAutoRunDisposer();
        }
    }

    @action.bound
    private async handleDragEnd(result: DropResult) {
        if (result.destination) {
            const [movedVoteItem] = this.member.vote.splice(result.source.index, 1);
            this.member.vote.splice(result.destination.index, 0, movedVoteItem);
            movedVoteItem.excluded = false;
        }

        await topicRepository.vote(this.topic.id, this.member.vote);
    }

    private getItemClassName(isDragging: boolean) {
        if (isDragging) {
            return "card bg-info text-white";
        }
        return "card bg-secondary text-dark";
    }

    @computed
    private get canVote() {
        return !this.topic?.isClosed && this.member.isVotingMember;
    }

    render() {
        if (!this.topic) {
            return <Loader />;
        }

        if (!this.member?.vote?.length) {
            return <div className="text-center">No nominations have been added yet</div>;
        }

        return (
            <div>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId="droppable" isDropDisabled={!this.canVote}>
                        {provided => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>

                                {
                                    this.member.vote
                                        .map((voteItem, index) =>
                                            <Draggable key={voteItem.nominationId} draggableId={voteItem.nominationId} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={provided.draggableProps.style}
                                                        className={this.getItemClassName(snapshot.isDragging)}>

                                                        <VoteItem member={this.member} topicId={this.props.topicId} voteItem={voteItem} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                }

                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                { /** Spacing at the bottom helps when reordering bottom item, especially on mobile */}
                <div className="pb-5" />
            </div>
        );
    }
}