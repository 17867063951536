import React from "react";

export class AboutCalculation extends React.Component {
    render() {
        return (
            <div>
                <h2> 
                    How is the vote calculated?
                </h2>
                <div className="ms-5">
                    <p>
                        Once everyone has had a chance to rank their choices, the topic manager can close the vote. The winner
                        is calculated by using a hybrid of Borda Count and Instant Run-off Voting. This voting methodology aims
                        to find a more consensus driven result than a majority driven result.
                    </p>
                        
                    <h5>Everything is assigned a ranking</h5>
                    <p>
                        First, everyone's votes are assigned a rank value according to their preferential order. For example, 
                        imagine that Alice is part of a board game group that is trying to pick a game to play this week. Let's say there
                        are 3 nominations. Alice's top choice is Settlers of Catan, and her second choice is Terra Mystica.
                        Her last choice is Twilight Imperium. These will each be assigned a ranking in Alice's ballot. 
                        
                        <p>
                            <i>
                                There's just one more wringle: 
                                <br/>
                                Lower preferences will be weighted more heavily. That means that the lower an item is in Alice's ballot, the more emphasized
                                her low opinion of it will be in the ranking. The purpose of this is to make the ranking more consensus driven. We want to land
                                on an option that everyone is okay with, so the lower a nomination is in your ballot, the more serious we consider that preference to be.
                            </i>
                        </p>
                    </p>
                    <h5>The lowest ranked is eliminated</h5>
                    <p>
                        Then, we add up everyone's ranks, and figure out which nomination scored the lowest. That one is eliminated
                        from everyone's ballots, and <i>the rest of their votes slide up to fill the gap</i>. Returning to our example, 
                        let's say Settlers of Catan was very poorly ranked on the rest of the group's ballots. Alice's first choice
                        was eliminated :( The good news is that she didn't waste her vote. Terra Mystica slides up to become her new top pick.
                    </p>
                    <h5>Repeat</h5>
                    <p>
                        Now that there's one less nomination on the table, we run the calculations again, and again, eliminating the lowest
                        ranked item each time until we are left with just one nomination - the winner!
                    </p>
                </div>

                <h2>What about vetoes?</h2>
                <div className="ms-5">
                    <p>
                        If vetoes are enabled by the topic manager, then voters can issue as many vetoes as they like. If a nomination is vetoed, 
                        it will be removed from everyone's ballot during vote calculation, and their other choices will slide up to fill the gap. 
                        That means that if your top choice ended up getting vetoed you didn't waste your vote. Your second pick will slide up to 
                        become your new top pick.
                    </p>
                </div>

                <h2>What does it mean to have "No Opinion" on something in my vote?</h2>
                <div className="ms-5">
                    <p>
                        Marking a nomination as No Opinion means that you will rely instead on the wisdom of the group. In this case, your rank value for that
                        nomination will be set to the average rank value of all other voters.
                    </p>
                </div>
            </div>
        )
    }
}