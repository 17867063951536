import { TopicDto, MemberDto, Vote, NominationDto, TopicMode, MembershipDto } from 'common';
import { Repository } from './repository';

const RESOURCE = "/api/topics";
class TopicRepository extends Repository {

    async createTopic(name:string, mode?: TopicMode): Promise<TopicDto> {
        let url = `${RESOURCE}/${encodeURIComponent(name)}`;
        if (mode) {
            url += `?mode=${encodeURIComponent(mode)}`
        }
        const response = await this.post(url, null);
        return response.data;
    }

    async fetchTopic(topicId: string): Promise<TopicDto> {
        const response = await this.get(`${RESOURCE}/${topicId}`);
        return response.data as TopicDto;
    }

    async fetchMyMemberships(): Promise<MembershipDto[]> {
        const response = await this.get(`${RESOURCE}/my-memberships`);
        return response.data as MembershipDto[];
    }

    async closeTopic(topicId: string): Promise<TopicDto> {
        return await this.put(`${RESOURCE}/${topicId}/close`);
    }

    async reopenTopic(topicId: string): Promise<TopicDto> {
        return await this.delete(`${RESOURCE}/${topicId}/close`);
    }

    async updateAllowVetoes(topicId: string, allowVetoes:boolean): Promise<TopicDto> {
        if (allowVetoes) {
            const response = await this.put(`${RESOURCE}/${topicId}/allowVetoes`);
            return response.data;
        }
        else {
            const response = await this.delete(`${RESOURCE}/${topicId}/allowVetoes`);
            return response.data;
        }
    }

    async updateAllowNominate(topicId: string, allowNominate:boolean): Promise<TopicDto> {
        if (allowNominate) {
            const response = await this.put(`${RESOURCE}/${topicId}/allowNominate`);
            return response.data;
        }
        else {
            const response = await this.delete(`${RESOURCE}/${topicId}/allowNominate`);
            return response.data;
        }
    }
    
    async updateAutoClosePlayerCount(topicId: string, autoClosePlayerCount:number|undefined): Promise<TopicDto> {
        if (autoClosePlayerCount) {
            const response = await this.put(`${RESOURCE}/${topicId}/autoClosePlayerCount/${autoClosePlayerCount}`);
            return response.data;
        }
        else {
            const response = await this.delete(`${RESOURCE}/${topicId}/autoClosePlayerCount`);
            return response.data;
        }
    }

    async join(topicId: string): Promise<MemberDto> {
        const response = await this.put(`${RESOURCE}/${topicId}/member`);
        return response.data as MemberDto;
    }

    async participate(topicId: string): Promise<MemberDto> {
        const response = await this.put(`${RESOURCE}/${topicId}/member/voting`);
        return response.data as MemberDto;
    }

    async withdraw(topicId: string): Promise<MemberDto> {
        const response = await this.put(`${RESOURCE}/${topicId}/member/observing`);
        return response.data as MemberDto;
    }

    async ready(topicId: string): Promise<MemberDto> {
        const response = await this.put(`${RESOURCE}/${topicId}/member/ready`);
        return response.data as MemberDto;
    }

    async notReady(topicId: string): Promise<MemberDto> {
        const response = await this.delete(`${RESOURCE}/${topicId}/member/ready`);
        return response.data as MemberDto;
    }

    async addNomination(topicId: string, nomination:NominationDto): Promise<TopicDto> {
        const response = await this.post(`${RESOURCE}/${topicId}/nomination`, nomination);
        return response.data as TopicDto;
    }

    async deleteNomination(topicId: string, nominationId:string): Promise<MemberDto> {
        return await this.delete(`${RESOURCE}/${topicId}/nomination/${nominationId}`);
    }

    async vote(topicId: string, vote:Vote): Promise<Vote> {
        const response = await this.put(`${RESOURCE}/${topicId}/vote`, vote);
        return response.data as Vote;
    }
}

export const topicRepository = new TopicRepository();