import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CreateTopic } from "../components/create-topic";
import { Landing } from "../components/landing";
import { MyMemberships } from "../components/my-memberships";

export class Home extends React.Component<RouteComponentProps> {
    render() {
        return (
            <div>
                <Landing />
                <CreateTopic {...this.props} />
                <div className="mt-4" />
                <MyMemberships />
            </div>
        );
    }
}