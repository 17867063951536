import { MemberDto } from "common";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import sanitizeHtml from 'sanitize-html';
import { VoteItemDto } from "../../../common/dist/vote-item.dto";
import { topicRepository } from "../repositories/topic.repository";
import { topicStore } from "../stores/topic.store";

interface VoteItemProps {
    topicId: string;
    member: MemberDto;
    voteItem: VoteItemDto;
}

const COLLAPSED_STYLE = {
    "WebkitLineClamp": "4",
    "display": "-webkit-box",
    "WebkitBoxOrient": "vertical",
    "overflow": "hidden"
};

@observer
export class VoteItem extends React.Component<VoteItemProps> {


    @observable
    private expanded = false;

    constructor(props: VoteItemProps) {
        super(props);
        makeObservable(this);
    }

    private get member() {
        return this.props.member;
    }

    private get vote() {
        return this.member.vote;
    }

    @computed
    private get voteItem() {
        return this.props.voteItem;
    }

    private get topic() {
        return topicStore.getTopic(this.props.topicId)!;
    }

    private get nomination() {
        return this.topic.nominations
            .filter(n => n.id === this.nominationId)[0];
    }

    private get nominationId() {
        return this.props.voteItem.nominationId;
    }

    @computed
    private get isVetoed() {
        return this.voteItem?.vetoed;
    }

    @computed
    private get isExcluded() {
        return this.voteItem?.excluded;
    }

    @action.bound
    private async handleClickVeto() {
        this.voteItem.vetoed = true;
        this.voteItem.excluded = false;
        this.updateVote();
    }

    @action.bound
    private async handleClickUnveto() {
        this.voteItem.vetoed = false;
        this.voteItem.excluded = false;
        this.updateVote();
    }

    @action.bound
    private async handleClickExclude() {
        this.voteItem.vetoed = false;
        this.voteItem.excluded = true;
        this.updateVote();
    }

    @action.bound
    private async handleClickInclude() {
        this.voteItem.vetoed = false;
        this.voteItem.excluded = false;
        this.updateVote();
    }

    @action.bound
    private async handleClickRemove() {
        if (!this.nominationId) {
            return;
        }

        const member = await topicRepository.deleteNomination(this.topic.id, this.nominationId);
        Object.assign(this.member, member);
    }

    @action.bound
    private async toggleExpansion() {
        this.expanded = !this.expanded;
    }

    private async updateVote() {
        const vote = await topicRepository.vote(this.topic.id, this.vote);
        Object.assign(this.vote, vote);
    }

    @computed
    private get nominationStyle(): CSSProperties {
        const style: CSSProperties = {
            fontSize: "1.27em"
        };

        if (this.isVetoed) {
            style.textDecoration = "line-through";
        }
        if (this.isExcluded) {
            style.fontStyle = "italic";
            style.opacity = "45%";
        }
        return style;
    }

    @computed
    private get canModify() {
        return !this.topic.isClosed && this.member.isVotingMember;
    }

    @computed
    private get absoluteUrl() {
        if (!this.nomination.link) {
            return null;
        }
        if (this.nomination.link.startsWith("http://") || this.nomination.link.startsWith("https://")) {
            return this.nomination.link;
        }
        return `//${this.nomination.link}`;
    }

    @computed
    private get safeDescription() {
        if (!this.nomination.description) {
            return "";
        }

        const descriptionWithHtmlWhitespace = this.nomination.description
            .replace(/\n/g, "<br/>")
            .replace(/ {2}/g, "&nbsp;&nbsp;");

        const clean = sanitizeHtml(descriptionWithHtmlWhitespace, {
            allowedTags: ['b', 'i', 'em', 'strong', 'p', 'br'],
        });

        return `<i>${clean}</i>`;
    }

    render() {
        if (!this.nomination) {
            return null;
        }

        return (
            <Card.Body>
                <Row>
                    <Col>
                        <p style={this.nominationStyle}>
                            {this.nomination.name}
                        </p>
                    </Col>
                    {
                        this.absoluteUrl
                        &&
                        <Col xs="auto">
                            <div className="me-auto">
                                <a href={this.absoluteUrl} target="_blank" rel="noopener noreferrer" className="btn btn-success"><i className="fa fa-link" /></a>
                            </div>
                        </Col>
                    }
                    <Col xs="auto">
                        {
                            this.renderControls()
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        {
                            this.nomination.thumbnailUrl
                            &&
                            <img className="img-thumbnail" alt="Thumbnail" style={{maxHeight: 100, maxWidth: 100}} src={this.nomination.thumbnailUrl} />
                        }
                    </Col>
                    <Col onClick={this.toggleExpansion}>
                        <div style={this.expanded ? {} : COLLAPSED_STYLE as any} dangerouslySetInnerHTML={{__html: this.safeDescription}} />
                    </Col>
                </Row>

            </Card.Body>
        )
    }

    renderControls() {
        let controlVariant = "info";
        let controlTitle = "";
        if (this.isVetoed) {
            controlVariant = "danger";
            controlTitle = "VETOED";
        }
        else if (this.isExcluded) {
            controlVariant = "warning";
            controlTitle = "NO OPINION"
        }

        return (
            <div className="me-auto">
                <Dropdown>
                    <DropdownToggle variant={controlVariant} disabled={!this.canModify}>{controlTitle}</DropdownToggle>
                    <Dropdown.Menu>
                        {
                            this.isExcluded
                                ? <Dropdown.Item title="Click here to include this item in your vote" onClick={this.handleClickInclude} ><i className="far fa-check-square me-1" /> No opinion</Dropdown.Item>
                                : <Dropdown.Item title="Click here if you have no opinion on this item. Your vote will not affect it" onClick={this.handleClickExclude} ><i className="far fa-square me-1" /> No opinion</Dropdown.Item>
                        }
                        {
                            this.isVetoed
                                ? <Dropdown.Item title="Click here to remove your veto of this item" onClick={this.handleClickUnveto} ><i className="far fa-check-square me-1" /> Veto</Dropdown.Item>
                                : null
                        }
                        {
                            !this.isVetoed && this.topic.allowVeto
                                ? <Dropdown.Item title="Click here to veto this item. It won't be able to win" onClick={this.handleClickVeto} ><i className="far fa-square me-1" /> Veto</Dropdown.Item>
                                : null
                        }
                        {
                            (this.nomination.creatorId === this.member.userId || this.member.isManager)
                            &&
                            <Dropdown.Item title="Changed your mind and wish you never added this? Click here to remove this nomination" onClick={this.handleClickRemove} ><i className="fas fa-times me-1" /> Remove</Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}